var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"overlay-color":"rgba(0,0,0,0.4)","overlay-opacity":"0.8","max-width":"480","persistent":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"px-3",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.addBranch)}}},[_c('v-card',{ref:"form",attrs:{"flat":""}},[_c('v-card-title',[_c('span',{staticClass:"ft font-size-lg font-weight-bold blue-grey--text ml-1"},[_vm._v("Edit Branch ")]),_c('v-spacer')],1),_c('v-card-text',[_c('v-col',[_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","autofocus":"","label":"Name","error-messages":errors[0],"hint":"Name of branch"},model:{value:(_vm.branch.name),callback:function ($$v) {_vm.$set(_vm.branch, "name", $$v)},expression:"branch.name"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required|max:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Telephone Number","error-messages":errors[0],"hint":"Branches telephone number"},model:{value:(_vm.branch.telephoneNumber),callback:function ($$v) {_vm.$set(_vm.branch, "telephoneNumber", $$v)},expression:"branch.telephoneNumber"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"ft font-weight-medium font-size-sm",attrs:{"outlined":"","label":"Location","error-messages":errors[0],"hint":"Enter branch location"},model:{value:(_vm.branch.location),callback:function ($$v) {_vm.$set(_vm.branch, "location", $$v)},expression:"branch.location"}})]}}],null,true)})],1),_c('v-row',[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1",attrs:{"tag":"div","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{staticClass:"ft font-weight-medium font-size-md",attrs:{"items":_vm.regions,"outlined":"","label":"Region","error-messages":errors[0]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"ft font-weight-medium font-size-sm"},[_vm._v(_vm._s(item))])]}}],null,true),model:{value:(_vm.branch.region),callback:function ($$v) {_vm.$set(_vm.branch, "region", $$v)},expression:"branch.region"}})]}}],null,true)})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"ft font-weight-medium font-size-sm text-capitalize",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"ft font-weight-medium font-size-sm text-capitalize",attrs:{"type":"submit","loading":_vm.loading,"disabled":_vm.loading,"color":"primary"}},[_vm._v(" Save ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }